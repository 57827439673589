import {
    Container,
    Flex,
    Text,
    Heading,
    ButtonGroup,
    Box,
    Grid,
    Link,
    Center,
    Wrap,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import { GetServerSideProps } from 'next';
import Layout from 'components/Layout';
import InfoSection from 'components/pages/home/InfoSection';
import { DarkButton, LightButton, RainbowButton } from 'components/Buttons';
import queryRoadmaps from 'lib/api/queryRoadmaps';
import { serialize } from 'lib/utils/functions';
import PublicRoadmapCard from 'components/pages/RoadmapCard/PublicRoadmapCard';
import { useState } from 'react';
import PawPath from 'components/pages/home/PawPath';
import RoundedLineTop from 'components/pages/home/RoundedLineTop';
import RoundedLineMiddle from 'components/pages/home/RoundedLineMiddle';
import RoundedLineBottom from 'components/pages/home/RoundedLineBottom';
import Image from 'next/image';
import Footer from 'components/Footer/Footer';
import { DEFAULT_ROADMAP_GRADIENT } from 'lib/constants';
import { Like, RoadmapPermission } from '@prisma/client';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { PublicRoadmap } from 'lib/types';

type HomePageProps = {
    popular: PublicRoadmap[];
};

export const getServerSideProps: GetServerSideProps<HomePageProps> = async () => {
    const popular = await queryRoadmaps([{ score: 'desc' }], 4);

    return {
        props: {
            popular: serialize(popular.filter((roadmap) => !!roadmap.currentVersion)),
        },
    };
};

const updateRoadmaps = (roadmapId: string, like: Like | null, session: Session | null) => (roadmaps: PublicRoadmap[]) =>
    roadmaps.map((roadmap) =>
        roadmap.id === roadmapId
            ? {
                  ...roadmap,
                  likes: like
                      ? [...roadmap.likes, like]
                      : roadmap.likes.filter((existingLikes) => existingLikes.userId !== session?.userId),
              }
            : roadmap,
    );

export default function LandingPage({ popular: initialPopular }: HomePageProps) {
    const [popular, setPopular] = useState(initialPopular);
    const { colorMode } = useColorMode();
    const borderColor = useColorModeValue('gray.200', 'gray.500');
    const alphaBgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.400');

    const { data: session } = useSession();

    const onLike = (roadmap: PublicRoadmap, like: Like | null) => {
        setPopular(updateRoadmaps(roadmap.id, like, session));
    };

    const router = useRouter();

    return (
        <Layout noBox noFooter>
            <Head>
                <title>Create and Share Public Roadmaps for Free | Goosly</title>
                <meta
                    name="description"
                    content="Create free, simple and public roadmap plans for your build in public projects and share them with your community. Get started today!"
                />
            </Head>
            <Box overflowX="hidden">
                <Box as="main">
                    <Flex
                        as="section"
                        align="center"
                        direction="column"
                        position="relative"
                        overflow="hidden"
                        w="100%"
                        mb="6"
                        pt={{ base: 4, lg: 12 }}
                    >
                        <Box position="absolute" right={-24} top={-80} transform="rotate(135deg)" zIndex={-1}>
                            <PawPath />
                        </Box>
                        <Text my="6" fontFamily="Montserrat">
                            plan • in • public
                        </Text>
                        <Heading
                            as="h1"
                            textAlign="center"
                            maxWidth="760px"
                            bgGradient={`linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end}, ${DEFAULT_ROADMAP_GRADIENT.green})`}
                            bgClip="text"
                            mb={9}
                            size="3xl"
                            lineHeight={1.2}
                        >
                            Stay accountable with public roadmaps
                        </Heading>
                        <ButtonGroup variant="outline" size="md" borderRadius={8} spacing={3} fontSize="md">
                            <Flex align="center" direction="column" gap={2}>
                                <RainbowButton size="md" onClick={() => router.push('/auth/signup')}>
                                    Get started
                                </RainbowButton>
                                <Text as="p" fontSize="md" fontStyle="italic" mb="8">
                                    {`It's free! 🚀`}
                                </Text>
                            </Flex>
                            <LightButton size="md" onClick={() => router.push('/discover')}>
                                Get inspired
                            </LightButton>
                        </ButtonGroup>
                    </Flex>
                    <Flex as="section" align="center" direction="column" mb="24" w="100%" position="relative">
                        <Box position="absolute" left="-250px" zIndex={-1} opacity="0.5">
                            <RoundedLineTop />
                        </Box>
                        <Container maxW="5xl" maxH="500px" centerContent mb={12}>
                            <Box
                                bgColor={alphaBgColor}
                                padding={3}
                                w="100%"
                                rounded="md"
                                border="1px solid"
                                borderColor={borderColor}
                            >
                                <Box border="1px solid" borderColor={borderColor} borderRadius={5}>
                                    <iframe
                                        src={`https://goos.ly/ckyge3jlk729543o7cyo9r7qz/embed?mode=${colorMode}`}
                                        width="100%"
                                        height="500px"
                                    ></iframe>
                                </Box>
                            </Box>
                        </Container>
                        <Flex align="center" direction="column" textAlign="center" maxW={{ base: 'auto', lg: '750px' }}>
                            <Text as="p" fontSize="2xl" mb="4">
                                👇
                            </Text>
                            <Text mb="2" fontSize="lg" fontFamily="Montserrat">
                                What is Goosly?
                            </Text>
                            <Heading fontWeight="extrabold" mb="4">
                                Free tool to create public roadmaps for your projects
                            </Heading>
                            <Text fontSize="lg" px={12}>
                                Goosly is a free, simple roadmap tool for build-in-public projects. It allows you to
                                style, embed and share project and product plans with a community, in the form of a
                                roadmap that can be duplicated, used and shared for free.
                            </Text>
                        </Flex>
                    </Flex>
                    <Container as="section" maxW="6xl" position="relative">
                        <Box position="absolute" right="-5vw" w="20vw" zIndex={-1} opacity="0.5">
                            <RoundedLineMiddle />
                        </Box>
                        <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} mb="16" gap={8}>
                            <InfoSection
                                title="What can I do?"
                                subtitle="Create a roadmap for build-in-public projects in minutes"
                                description={[
                                    '🔎  Keep your people in the loop by sharing your plan',
                                    '💡  Get inspired by browsing through public roadmaps',
                                    '🎨  Customize your roadmap with colors and groups',
                                    '✍🏽  Publish and share your plans on socials, or embed it',
                                ]}
                            />
                            <Container>
                                <Image src="/landing-1.png" alt="Image 1" width={654} height={444} />
                            </Container>
                        </Grid>
                        <Flex
                            direction={{ base: 'column-reverse', lg: 'row' }}
                            mb="20"
                            gap={8}
                            align="center"
                            width="100%"
                        >
                            <Container>
                                <Image src="/landing-2.png" alt="Image 2" width={560} height={444} />
                            </Container>
                            <Box px={{ base: 4, lg: 0 }} pl={{ base: 'none', lg: 12 }}>
                                <InfoSection
                                    title="What can I use it for?"
                                    subtitle="The public roadmap people want to be part of"
                                    description={[
                                        "There's a lot you can do with Goosly:",
                                        '🚀  Manage a product or crypto/NFT project',
                                        '🗂️  Share your startup journey with an audience',
                                        '🏠  Build a community by planning in public',
                                        "🏝️  Track your team's efforts, and their holidays",
                                    ]}
                                />
                            </Box>
                        </Flex>
                    </Container>
                    <Flex as="section" direction="column" align="center" gap={8} position="relative">
                        <Box position="absolute" left="-400px" top="-220px" zIndex={-1} opacity="0.5">
                            <RoundedLineBottom />
                        </Box>
                        <Box position="absolute" right={-16} bottom={-80} transform="rotate(50deg)" zIndex={-1}>
                            <PawPath />
                        </Box>
                    </Flex>
                    <Heading mb="4" textAlign="center">
                        Discover popular roadmaps
                    </Heading>
                    <Flex
                        direction="column"
                        rounded="md"
                        w={{ base: '90%', lg: 'auto' }}
                        bgColor={alphaBgColor}
                        border="1px solid"
                        borderColor={borderColor}
                        mb={20}
                        mx={{ base: 'auto', lg: 8 }}
                        p={4}
                    >
                        <Wrap spacing={4} justify="center" align="center">
                            {popular.map((roadmap) => (
                                <PublicRoadmapCard
                                    key={roadmap.id}
                                    roadmap={roadmap}
                                    onLike={onLike}
                                    canEdit={
                                        roadmap.ownerId === session?.userId ||
                                        roadmap.sharedWith.some(
                                            (roadmapUser) =>
                                                roadmapUser.userId === session?.userId &&
                                                roadmapUser.permission === RoadmapPermission.EDITOR,
                                        )
                                    }
                                />
                            ))}
                        </Wrap>
                        <Center mt={8}>
                            <Link href="/discover" textDecoration="none">
                                <DarkButton size="sm">More roadmaps 👉</DarkButton>
                            </Link>
                        </Center>
                    </Flex>
                </Box>
                <Footer />
            </Box>
        </Layout>
    );
}
